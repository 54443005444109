import GitHubButton from "react-github-btn";

export const Star = () => (
  <GitHubButton
    href="https://github.com/codemuse-app/codemuse"
    data-color-scheme="no-preference: dark; light: dark; dark: dark;"
    data-icon="octicon-star"
    data-size="large"
    data-show-count="true"
    aria-label="Star codemuse-app/codemuse on GitHub"
  >
    Star on GitHub
  </GitHubButton>
);
